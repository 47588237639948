<template>
  <img
    v-bind="$attrs"
    v-if="src && !imgError"
    :src="getBaseSrc(src)"
    @error="onImageError"
  />
  <img v-else v-bind="$attrs" src="@/assets/user_placeholder.png" />
</template>

<script>
export default {
  name: 'app-avatar',
  props: {
    src: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  data: () => ({
    imgError: false,
  }),
  methods: {
    getBaseSrc (path) {
      if (!path) return ''

      let src = ''
      if (process.env.NODE_ENV === 'development') {
        const base = this.$http.defaults.baseURL.match(/^https?:\/\/[^/]+/g)[0]
        src = base
      }
      return src + path
    },
    onImageError () {
      this.imgError = true
    },
  },
}
</script>
